$enable-gradients: true;
$enable-shadows: true;

$spacer: 1rem !default;
$font-family-sans-serif: 'Open Sans', sans-serif;
$font-weight-base: lighter !default;

// scss-docs-start headings-variables
$headings-margin-bottom:      $spacer * .5 !default;
$headings-font-family:        $font-family-sans-serif !default;
$headings-font-style:         lighter !default;
$headings-font-weight:        500 !default;
$headings-line-height:        1.2 !default;
$headings-color:              #404040 !default;

/*$theme-colors: (
        "primary":    $primary,
        "secondary":  $secondary,
        "success":    $success,
        "info":       $info,
        "warning":    $warning,
        "danger":     $danger,
        "light":      $light,
        "dark":       $dark
)

$theme-colors: (
        "info": #4F7CAC,
        "danger": tomato,
        "dark": #272d2f,
        "gray-dark": #272d2f,
);*/    

@import "../../node_modules/bootstrap";
                